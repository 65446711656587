import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../DivadloDI/DiPage.css";
import { Helmet } from "react-helmet-async";
import Navbar from "../../components/NavBar/Navbar";
import DiLogo from "../../assets/DivadloDI/di-logo.png";
import ScrollButton from "../../components/ScrollTopButton/ScrollButton";
import ActorPopup from "../../components/ActorPopup/ActorPopup";
import Adela from "../../assets/DivadloDI/adela.webp";
import Adela1 from "../../assets/actors/adela/1.webp";
import Adela2 from "../../assets/actors/adela/2.webp";
import Adela3 from "../../assets/actors/adela/3.webp";
import Adela4 from "../../assets/actors/adela/4.webp";
import Adela5 from "../../assets/actors/adela/5.webp";
import Adela6 from "../../assets/actors/adela/6.webp";
import Adela7 from "../../assets/actors/adela/7.webp";
import Adela8 from "../../assets/actors/adela/8.webp";
import Adela9 from "../../assets/actors/adela/9.webp";
import Adela10 from "../../assets/actors/adela/10.webp";
import Adela11 from "../../assets/actors/adela/11.webp";
import Adela12 from "../../assets/actors/adela/12.webp";
import Adela13 from "../../assets/actors/adela/13.webp";
import Adela14 from "../../assets/actors/adela/14.webp";
import Adela15 from "../../assets/actors/adela/15.webp";
import Adela16 from "../../assets/actors/adela/16.webp";
import Adela17 from "../../assets/actors/adela/17.webp";
import Adela18 from "../../assets/actors/adela/18.webp";
import Adela19 from "../../assets/actors/adela/19.webp";
import Adela20 from "../../assets/actors/adela/20.webp";
import Adela21 from "../../assets/actors/adela/21.webp";
import Amy from "../../assets/DivadloDI/amy.webp";
import Amy1 from "../../assets/actors/amy/1.webp";
import Amy2 from "../../assets/actors/amy/2.webp";
import Amy3 from "../../assets/actors/amy/3.webp";
import Amy4 from "../../assets/actors/amy/4.webp";
import Amy5 from "../../assets/actors/amy/5.webp";
import Amy6 from "../../assets/actors/amy/6.webp";
import Amy7 from "../../assets/actors/amy/7.webp";
import Amy8 from "../../assets/actors/amy/8.webp";
import Adam from "../../assets/DivadloDI/adam.webp";
import Adam1 from "../../assets/actors/adam/1.webp";
import Adam2 from "../../assets/actors/adam/2.webp";
import Adam3 from "../../assets/actors/adam/3.webp";
import Adam4 from "../../assets/actors/adam/4.webp";
import Adam5 from "../../assets/actors/adam/5.webp";
import Adam6 from "../../assets/actors/adam/6.webp";
import Adam7 from "../../assets/actors/adam/7.webp";
import Adam8 from "../../assets/actors/adam/8.webp";
import Adam9 from "../../assets/actors/adam/9.webp";
import Adam10 from "../../assets/actors/adam/10.webp";
import Adam11 from "../../assets/actors/adam/11.webp";
import Adam12 from "../../assets/actors/adam/12.webp";
import Adam13 from "../../assets/actors/adam/13.webp";
import Adam14 from "../../assets/actors/adam/14.webp";
import Adam15 from "../../assets/actors/adam/15.webp";
import Marketa from "../../assets/DivadloDI/marketa.webp";
import Marketa1 from "../../assets/actors/marketa/1.webp";
import Marketa2 from "../../assets/actors/marketa/2.webp";
import Marketa3 from "../../assets/actors/marketa/3.webp";
import Tomas from "../../assets/DivadloDI/tomas.webp";
import Tomas1 from "../../assets/actors/tomas/1.webp";
import Tomas2 from "../../assets/actors/tomas/2.webp";
import Tomas3 from "../../assets/actors/tomas/3.webp";
import Tomas4 from "../../assets/actors/tomas/4.webp";
import Tomas5 from "../../assets/actors/tomas/5.webp";
import Tomas6 from "../../assets/actors/tomas/6.webp";
import Footer from "../../components/Footer/Footer";

const DIPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedActor, setSelectedActor] = useState(null);
  const location = useLocation();

  const actors = [
    {
      name: "Adéla Pellarová",
      mainImage: Adela,
      paragraphs: [
        "Adéla Pellarová (nar. 1976 v Litomyšli, absolutorium DAMU 1998)",
        "V současné době hraje, píše divadelní hry, režíruje, navrhuje scény inscenací, " +
          "vede Divadeliér ve Vysokém Mýtě, který je domovskou scénou profesionálnímu souboru divadla Di, " +
          "dramatickým kurzům pro děti i dospělé (Dr. ZDIV, Divan), Výstavám ve výloze, TV VV a kulturním akcím vzácných hostů " +
          "(koncerty, představení, přednášky, semináře, besedy, literární večery...) a plánuje v Divadeliéru otevřít Hereckou laboratoř.",
        "Lektoruje a rozvíjí herecké dovednosti. Nejvíce ji ovlivnil Ivan Vyskočil hereckou disciplínou Dialogické jednání, hlasovou výchovou " +
          "ji provedla Šárka Šternbergová, pohybovou průpravou Zdena Kratochvílová, hereckou Hana Smrčková, dramaturgickou Zdena Josková, scénografickou Karel Vostárek, " +
          "režijní Aleš Bergman,  a další úžasní pedagogové DAMU (Císař, Provazník, Machková, Kasíková, Valenta, Lhotská, Ulrychová...). " +
          "Divadelně obohacující pro ni byly studijní pobyty v Anglii, Maďarsku a Dánsku, krom divadelních rolí si zahrála i ve studentském filmu Větvičky, " +
          "hrála i v dokumentárním filmu a ve videoklipu.",
        "Její scénáře naleznete v databázi Dilia, hra Spolu byla přeložena do polštiny (Wspolnie – Jan Węglowski), píše básně.",
      ],
      photos: [
        Adela1,
        Adela2,
        Adela3,
        Adela4,
        Adela5,
        Adela6,
        Adela7,
        Adela8,
        Adela9,
        Adela10,
        Adela11,
        Adela12,
        Adela13,
        Adela14,
        Adela15,
        Adela16,
        Adela17,
        Adela18,
        Adela19,
        Adela20,
        Adela21,
      ],
    },
    {
      name: "Amálie Popelářová",
      mainImage: Amy,
      paragraphs: [
        "Amálie Popelářová (nar. 2006 v Praze)",
        "Navštěvovala dramaťák v Minoru vedený Markétou Pellarovou a Ivanou Vostárkovou, absolvovala DAMU junior a má titul JrA. :-), " +
          "dabovala ve snímku Normalizační loutka, navštěvovala a stále působí v Dr. ZDIV, spolupracovala při vzniku inscenací s divadlem Mopeadd, " +
          "zúčastnila se kurzu Dialogického jednání vedeným Pavlem Kolajou a Janem Nepomukem Piskačem, kurzu pantomimy vedeným Milošem Samkem, " +
          "filmového kurzu s Tomášem Polenským a nyní hraje v divadle Di. V současné době studuje gymnázium a ztvárnila přes dvacet větších či menších rolí.",
      ],
      photos: [Amy1, Amy2, Amy3, Amy4, Amy5, Amy6, Amy7, Amy8],
    },
    {
      name: "Adam Horký",
      mainImage: Adam,
      paragraphs: [
        "Adam Horký (nar. 2002 v Trutnově)",
        "V dětství navštěvoval dramatický kroužek, po střední škole nastoupil do divadelního souboru v Úpici " +
          "(role Jeník v Prodané nevěstě a role Dílo v inscenaci Helena ve stínu bratří Čapků, která byla vytvořena " +
          "pod profesionálním vedením Hasana Zahiroviće). Rovněž spolupracuje s divadelním souborem v Červeném Kostelci " +
          "(role Jasona Willetta, inscenace Králičí nora). Absolvoval kurzy zaměřené na herectví, " +
          "hlasovou techniku a pohyb (lektoři Petr Pochop, Eva Spoustová, Petr Kracik, Zdeněk Stejskal a další).",
        "Pracuje v dabingové skupině Fénix prodabing.",
        "Od roku 2023 působí v profesionálním divadle Di ve Vysokém Mýtě.",
      ],
      photos: [
        Adam1,
        Adam2,
        Adam3,
        Adam4,
        Adam5,
        Adam6,
        Adam7,
        Adam8,
        Adam9,
        Adam10,
        Adam11,
        Adam12,
        Adam13,
        Adam14,
        Adam15,
      ],
    },
    {
      name: "Markéta Voříšková",
      mainImage: Marketa,
      paragraphs: ["Markéta Voříšková (nar. 1978 v Praze)",
        "V dětství byla členkou pražského dětského sboru i Rozhlasového sboru pod vedením dirigenta Čestmíra Staška. "+
        "Navštěvovala dramaťák i hodiny výtvarné výchovy, které jí předurčily budoucí pracovní náplň. Vystudovala Konzervatoř "+
        "Jaroslava Ježka - obor zpěv, který je její uměleckou doménou dodnes.",
        "První divadelní roli měla v divadelní skupině Divan (Tatíček zůstavitel) a s divadlem Di "+
        "spolupracuje od roku 2023 (komedie Na pavlači).",
        "Markéta pochází z umělecké rodiny a je prvorozenou dcerou hudebního skladatele Petra Hapky."
      ],
      photos: [Marketa1, Marketa2, Marketa3],
    },
    {
      name: "Tomáš Valek",
      mainImage: Tomas,
      paragraphs: [],
      photos: [Tomas1, Tomas2, Tomas3, Tomas4, Tomas5, Tomas6],
    },
  ];

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handlePopup = (id) => {
    setSelectedActor(actors[id]);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedActor(null);
  };

  return (
    <>
      <Helmet>
        <title>O nás | Divadlo Di</title>
        <meta name='description' content='Chceš se o nás dovědět trochu více? Zde jsou informace o divadle i o našich členech.'/>
        <meta name='keywords' content='o nás, about, divadlo, divadlo DI, DI, divadelní hry'/>
        <link rel='canonical' href='https://www.divadlodi.cz/#/divadloDI' />
      </Helmet>
      <Navbar />
      <div id='di' className='about-page'>
        <div className='banner'>
          <img src={DiLogo} alt='DI logo' className='image' />
        </div>
        <div className='history-today'>
          <div className='history'>
            <h2>Jak to vše začalo</h2>
            <p>
              Divadlo Di vzniklo v roce 2017 a založily ho Adéla Pellarová a
              Monika Münsterová. Po skvělých divadelních letech promluvil osud a
              Monika v roce 2023 musela divadelní činnost ukončit. V současné
              době je základnou divadla Di Adéla.
            </p>
            <p>
              Adéla má vystudovanou DAMU a garantuje divadelní kvalitu u všech
              představení divadla Di.
            </p>
          </div>
          <div className='today'>
            <h2>Jak jsme se dostali do součastnosti</h2>
            <p>
              Divadlo Di má zázemí a stálou scénu v Divadeliéru ve Vysokém Mýtě.
            </p>
            <p>
              V roce 2022 se k divadlu Di přidala herečka Amálie Popelářová do
              pohádky O Šípkové Růžence, následně i Jak Honza ke štěstí přišel a
              také herec a režisér Tomáš Valek (JAMU) na inscenaci Spolu.
            </p>
            <p>
              V roce 2023 rozšířil divadelní soubor herec Adam Horký (pohádka O
              Šípkové Růžence, Jak Honza ke štěstí přišel) a zpěvačka a herečka
              Markéta Voříšková (komedie Na pavlači).
            </p>
            <p>
              Od roku 2024 hraje divadlo Di ve složení herců Adéla Pellarová,
              Amálie Popelářová, Adam Horký, Markéta Voříšková, Tomáš Valek.
            </p>
            <p>
              Spolupracujeme s hudebníkem a skladatelem Šimonem Martínkem,
              klavíristkou Bárou Machovcovou.
            </p>
            <p>Podporujeme myšlenku šíření kvalitního divadla.</p>
            <p>
              Venkovní akce pro nás jsou v pohodě, máme vlastní bedny i s
              mikrofony.
            </p>
          </div>
        </div>

        <div id='team' className='team'>
          <h2>Členové divadla DI</h2>
          <div className='team-members'>
            <div className='team-member'>
              <img
                className='clickable hover-zoom'
                onClick={() => handlePopup(0)}
                src={Adela}
                alt='Team Member 1'
              />
              <h3 className='clickable' onClick={() => handlePopup(0)}>
                Adéla Pellarová
              </h3>
              {selectedActor && (
                <ActorPopup
                  show={showPopup}
                  onClose={closePopup}
                  actor={selectedActor}
                />
              )}
              <a href='https://pardubice.rozhlas.cz/divadelier-ve-vysokem-myte-koncentruje-kulturu-na-jedno-misto-zapojuje-i-vylohy-9180106'>
                Rozhovor s Adélou Pellarovou v Českém rozhlase Pardubice si
                můžete poslechnout <b>zde</b>
              </a>
            </div>
          </div>
        </div>
        <div className='team'>
          <h2>Hostující herci divadla DI</h2>
          <div className='team-members'>
            <div className='team-member'>
              <img
                className='clickable hover-zoom'
                onClick={() => handlePopup(1)}
                src={Amy}
                alt='Team Member 1'
              />
              <h3 className='clickable' onClick={() => handlePopup(1)}>
                Amálie Popelářová
              </h3>
            </div>
            <div className='team-member'>
              <img
                className='clickable hover-zoom'
                onClick={() => handlePopup(2)}
                src={Adam}
                alt='Team Member 1'
              />
              <h3 className='clickable' onClick={() => handlePopup(2)}>
                Adam Horký
              </h3>
            </div>
            <div className='team-member'>
              <img
                className='clickable hover-zoom'
                onClick={() => handlePopup(3)}
                src={Marketa}
                alt='Team Member 1'
              />
              <h3 className='clickable' onClick={() => handlePopup(3)}>
                Markéta Voříšková
              </h3>
            </div>
            <div className='team-member'>
              <img
                className='clickable hover-zoom'
                onClick={() => handlePopup(4)}
                src={Tomas}
                alt='Team Member 1'
              />
              <h3 className='clickable' onClick={() => handlePopup(4)}>
                Tomáš Valek
              </h3>
            </div>
          </div>
        </div>
        <div id='co-creators' className='co-creators'>
          <h2>Spolutvůrci</h2>
          <h3>Skladatelé a hudební interpreti</h3>
          <div className='team-members'>
            <div className='team-member'>
              <i class='i-center fa fa-music fa-5x' aria-hidden='true'></i>
              <h3>Bára Machovcová</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-music fa-5x' aria-hidden='true'></i>
              <h3>Šimon Martínek</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-music fa-5x' aria-hidden='true'></i>
              <h3>Eduard Příhoda ml.</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-music fa-5x' aria-hidden='true'></i>
              <h3>Patrik Žalud</h3>
            </div>
          </div>
          <h3>Výtvarníci</h3>
          <div className='team-members'>
            <div className='team-member'>
              <i class='i-center fas fa-edit fa-5x' aria-hidden='true'></i>
              <h3>Monika Münsterová</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fas fa-edit fa-5x' aria-hidden='true'></i>
              <h3>Anežka Plháková</h3>
            </div>
          </div>
          <h3>Technici a výrobci kulis a rekvizit</h3>
          <div className='team-members'>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Petr Kubík</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Ladislav Plšek</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Michal Veselý</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Jiří Holomek</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Barbara Wacková</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Libuše Münsterová</h3>
            </div>
          </div>
          <h3>A také</h3>
          <div className='team-members'>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Jana Urbanová</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Monika Vítková</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Petra Šplíchalová</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Eduard Příhoda senior</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Lenka Kaira Bauerová</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Ludvík Münster</h3>
            </div>
          </div>
          <div className='team-members'>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Míňa Podhájecká</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Mirka Voříšková</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Šárka Tomanová</h3>
            </div>
            <div className='team-member'>
              <i class='i-center fa fa-cogs fa-5x' aria-hidden='true'></i>
              <h3>Tomáš Outrata</h3>
            </div>
          </div>
        </div>
      </div>
      <ScrollButton />
      <Footer/>
    </>
  );
};

export default DIPage;
